import { Box, Paper, IconButton, Divider, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AiFillHome, AiFillCaretRight } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Card from '@mui/material/Card';
import { ChevronRight } from '@mui/icons-material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU } from '../../store/actions';

const BreadcrumbHeader = ({ title, titleStyle = {}, shouldGoBack = false, breadcrumbTitle = '', Actions = () => <></>, customBackgroundColor, sx = {} }) => {
    const location = useLocation().pathname;
    const routes = [
        { path: '/', breadcrumb: title },
        {
            path: 'profile',
            children: [
                { path: '', breadcrumb: location?.includes("/profile/update") ? breadcrumbTitle : title },
                { path: 'update', breadcrumb: title },
            ]
        },
        {
            path: 'rounds',
            breadcrumb: 'Funding Rounds',
            children: [
                {
                    path: 'create_round', breadcrumb: 'Create Round',
                    children: [
                        { path: ':id', breadcrumb: null }, // Breadcrumb for :id will not be rendered
                    ],
                },
                { path: 'edit_round', breadcrumb: null },
                { path: 'edit_round/:id', breadcrumb: title },
                { path: 'view', breadcrumb: null },
                { path: 'view/:id', breadcrumb: title },
            ],
        },
        {
            path: 'reports',
            breadcrumb: 'Reports',
            children: [
                {
                    path: 'create_report', breadcrumb: 'Create Report',
                    children: [
                        { path: ':id', breadcrumb: null }, // Breadcrumb for :id will not be rendered
                    ],
                },
                { path: 'edit_report', breadcrumb: null },
                { path: 'edit_report/:id', breadcrumb: title },
                // { path: 'view', breadcrumb: null },
                // { path: 'view/:id', breadcrumb: title },
            ],
        },
    ];


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const navigate = useNavigate();
    const open = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const drawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !open });
    };

    const breadcrumbs = useBreadcrumbs(routes);
    return (
        <>
            {/* {shouldGoBack && <IconButton sx={{backgroundColor: '#fff', boxShadow: 3}} onClick={()=>console.log(navigate(-1))}>
        <KeyboardBackspaceIcon />
      </IconButton>} */}
            <Card style={{ padding: isNotLargeScreen ? '1rem' : '1rem 2rem', boxShadow: "none", borderRadius: isNotLargeScreen ? 0 : '24px 0 0 0', background: customBackgroundColor ? customBackgroundColor : theme.palette?.customColors?.yellow_bg, ...sx }}>
                <Box
                    sx={{
                        backgroundColor: customBackgroundColor ? customBackgroundColor : theme.palette?.customColors?.yellow_bg,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1,
                    }}
                    className="breadcrumb-flex-wrap"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }} className="breadcrumb-box">
                        {/* <Link to="/" style={{ textDecoration: 'none' }}>
                            <AiFillHome
                                style={{ position: 'relative', top: '2px', fontSize: '16px' }}
                                color={theme.palette.secondary[800]}
                            />
                        </Link> */}
                        {isNotLargeScreen && <IconButton sx={{ padding: 0, display: 'grid', placeItems: 'center', marginRight: 1 }} onClick={drawerToggle}> <MenuRoundedIcon sx={{ width: '1.6rem', height: '1.6rem', padding: "5px" }} /> </IconButton>}
                        {breadcrumbs?.map(({ match, breadcrumb, key, location }, index) => {
                            // Skip the breadcrumb if it is null (e.g., for ':id')
                            if (!breadcrumb) return null;

                            return (
                                <Box key={key}>

                                    {location.pathname !== key ? (
                                        <>
                                            {index > 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {breadcrumbs?.length - 1 === index ? <Typography
                                                    variant='title'
                                                    sx={{
                                                        textDecoration: 'none',
                                                        fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem' },
                                                        margin: 0,
                                                        color: theme.palette.secondary[800],
                                                        fontWeight: 700,
                                                        width: 'max-content'
                                                    }}
                                                >
                                                    {breadcrumb}
                                                </Typography> :
                                                    <Link
                                                        to={match.pathname}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <Typography sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem' }, fontWeight: 700, color: theme.palette.customColors.indigo600, width: 'max-content' }}>
                                                            {breadcrumb}
                                                        </Typography>
                                                    </Link>
                                                }
                                                {breadcrumbs?.length - 1 !== index && <ChevronRight sx={{ fontSize: '1.5rem', color: theme.palette.secondary[800] }}
                                                    style={{ marginTop: '4px' }}
                                                // color={theme.palette.secondary[800]}
                                                />}
                                            </Box>}
                                        </>
                                    ) : (
                                        <Typography
                                            sx={{
                                                textDecoration: 'none',
                                                fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem' },
                                                margin: 0,
                                                color: theme.palette.secondary[800],
                                                fontWeight: 700,
                                                width: 'max-content',
                                                ...titleStyle
                                            }}
                                        >
                                            {breadcrumb}
                                        </Typography>
                                    )}
                                </Box>
                            )
                        })}

                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: isNotLargeScreen ? 'auto' : 'auto', bottom: isNotLargeScreen ? 'auto' : 'auto', zIndex: 3, right: '1rem' }}>
                        <Actions />
                    </Box>
                </Box>
                <Divider sx={{ marginTop: "1rem" }} />
            </Card>
        </>
    );
};

export default BreadcrumbHeader;
