import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PerfectScrollbar from "react-perfect-scrollbar"
import BreadcrumbHeader from '../../../layout/BreadcrumbsHeader'
import { SECONDARY, WHITEBG } from '../../../ui-components/CustomButton'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../../ui-components/ThemeBGWrapper'
import { FetchRoundViewDetails, FetchStartupDetails, FetchViewStartupDetails } from '../../../api'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, CircularProgress, Grid, Tab, Tabs, Typography, useMediaQuery, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { ReactComponent as RoundDetailsIcon } from "../../../assets/rounds_icons/round_details_icon.svg"
import { ReactComponent as RoundInvestorsIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import { ReactComponent as RoundDocumentsIcon } from "../../../assets/rounds_icons/round_documents.svg"
import ViewDetails from './ViewDetails'
// import RoundInvestors from './RoundInvestors'
// import RoundDocuments from './RoundDocuments'

import { ReactComponent as InfoIcon } from '../../../assets/help-circle.svg';
import CustomTabs from '../../../ui-components/CustomTabs'
import { containerRef, handleCopyClick } from '../../../utilities'
import { ContentCopyOutlined, FileCopy } from '@mui/icons-material'
import NeedHelpButton from 'common-components/NeedHelpButton'
import ViewProfile from './ViewProfile'
import ViewFeedback from './ViewFeedback'
// import RoundInvestorsNew from './RoundInvestorsNew'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));




const ViewProfileInformation = () => {
    const { id: round_id } = useParams();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();
    const Actions = () => {
        return (
            <>
                <NeedHelpButton />
            </>
        );
    };

    let tabValue = new URLSearchParams(window.location.search).get("page");
    // console.log("tabl",tabValue)

    const [value, setValue] = React.useState(tabValue || "view_details");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const roundTabs = [
        {
            label: "Profile",
            // icon: RoundDetailsIcon,
            value: "view_details",
        },
        // {
        //     label: "Investors",
        //     icon: RoundInvestorsIcon,
        //     value: "investors",
        // },
        {
            label: "Profile Views",
            // icon: RoundInvestorsIcon,
            value: "round_investors",
        },
        {
            label: "Investor Feedbacks",
            // icon: RoundDocumentsIcon,
            value: "documents"
        }
        // ,
        // {
        //     label: "Investors Documents",
        //     icon: RoundDetailsIcon,
        //     value: "investors_documents"
        // }
    ]

    const [copied, setCopied] = React.useState(false)

    const { data: startupDetails, isLoading: isLoadingStartupDetails, refetch: refetchStartupDetails } = FetchViewStartupDetails()
    // console.log(startupDetails, "startupDetails")
    return (
        <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
            <BreadcrumbHeader title={() => <>{isLoadingStartupDetails ? "Loading..." : isSmallScreen ? "View Details" : startupDetails?.data?.startup_name ? startupDetails?.data?.startup_name : "View Details"} {!isLoadingStartupDetails && <Tooltip title={copied ? "Copied to Clipboard" : "Copy Profile Url"}>
                <IconButton onClick={() => handleCopyClick(startupDetails?.data?.backrr_base_url, copied, setCopied)}>
                    {copied ? <FileCopy
                        sx={{ height: 20 }}
                    // sx={{ color: theme.palette.customColors.b300 }}
                    /> : <ContentCopyOutlined sx={{ height: 20 }} />}
                </IconButton>
            </Tooltip>}</>} Actions={Actions} sx={{ paddingBottom: 0 }} />
            {isLoadingStartupDetails ?
                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingTop: "1rem", bgColor: theme.palette.customColors.yellow_bg }}>
                    {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
                    <Box className='skeleton' height={80}>

                    </Box>
                    <Box className='skeleton' height={"calc(100% - 80px)"} marginTop={2}>

                    </Box>
                </Grid>
                : <>


                    <Grid xs={12} container bgColor={theme.palette.customColors.yellow_bg} height={"100%"}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box px={isSmallScreen ? "1rem" : "2rem"} bgColor={theme.palette.customColors.yellow_bg}>
                                    <CustomTabs data={roundTabs} value={value} isSmallScreen={isSmallScreen} className={classes} handleChange={handleChange} key={"Tabs Tabs"} sx={{ paddingTop: '1rem' }} bgColor={theme.palette.customColors.yellow_bg} />
                                </Box>
                                <TabPanel value="view_details" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: "calc(100vh - 210px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <ViewDetails data={startupDetails?.data} round_id={round_id} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="round_investors" sx={{ paddingInline: 0, height: '100%' }}>
                                    {/* <PerfectScrollbar containerRef={containerRef} style={{ height: "calc(100vh - 210px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}> */}
                                        <ViewProfile/>
                                    {/* </PerfectScrollbar> */}
                                </TabPanel>
                                <TabPanel value="documents" sx={{ paddingInline: 0, height: '100%' }}>
                                    {/* <PerfectScrollbar containerRef={containerRef} style={{ height: "calc(100vh - 210px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}> */}
                                        <ViewFeedback/>
                                    {/* </PerfectScrollbar> */}
                                </TabPanel>
                                
                                {/* <TabPanel value="investors" sx={{ paddingInline: 0, height: '100%', paddingTop: '0.5rem' }}>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: "calc(100vh - 180px)", paddingBottom: '0.5rem' }}>
                                        <RoundInvestors refetchStartupDetails={refetchStartupDetails} currency={isLoadingStartupDetails ? "" : startupDetails?.currency} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="round_investors" sx={{ paddingInline: 0, height: '100%', paddingTop: '0.5rem' }}>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: "calc(100vh - 180px)", paddingBottom: '0.5rem' }}>
                                        <RoundInvestorsNew refetchStartupDetails={refetchStartupDetails} currency={isLoadingStartupDetails ? "" : startupDetails?.currency} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="documents" sx={{ paddingInline: isSmallScreen ? "1rem" : "2rem", height: '100%' }}>
                                        <RoundDocuments refetchStartupDetails={refetchStartupDetails} data={startupDetails?.documents} />
                                </TabPanel> */}
                                {/* <TabPanel value="investors_documents" sx={{ paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                    <PerfectScrollbar style={{ height: "calc(100vh - 250px)" }}>
                                        <RoundDocuments data={startupDetails?.documents} />
                                    </PerfectScrollbar>
                                </TabPanel> */}
                            </TabContext>
                        </Box>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>
    )
}

export default ViewProfileInformation