import { Box, Grid } from '@mui/material'
import React from 'react'
import AuthWrapper1 from 'ui-components/AuthWrapper1'
import CustomBackgroundComponent from 'ui-components/CustomBackgroundComponent'
import AuthLogin from 'views/pages/AuthLogin'
import BannerImage from "assets/banner_image.webp"
import { useTheme } from '@emotion/react'

const OnboardingLayout = ({ children }) => {
    const theme = useTheme()
    return (
        <Grid xs={12} container sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", minHeight: "100vh", backgroundColor: theme.palette.customColors.yellow_bg }}>
            <Box className="container" sx={{ padding: { xs: "0 !important", md: "1rem !important", lg: "1rem !important" }, maxHeight: { xs: "100%", md: 600, lg: 730 } }}>
                <div class="form-section">
                    {/* <img
                        src="https://startup.backrr.in/static/media/Backrr_logo_symbol_white.7015032dde27fadf8b48.webp"
                        alt="Logo"
                        class="logo"
                    /> */}
                    {/* <AuthLogin /> */}
                    {children}
                </div>
                <div className='illustration-section' />
                {/* <img className='illustration-section' src={BannerImage} style={{ flex: 1, objectFit: 'contain', backgroundPosition: "left top", position: "relative", backgroundRepeat: 'no-repeat', display: "none" }} /> */}
            </Box>
        </Grid>
    )
}

export default OnboardingLayout