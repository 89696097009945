import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { containerRef } from "../utilities";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({
  graphData,
  width = "330px",
  height = "calc(100vh - 25rem)",
  chartMaxWidth = 330,
}) => {
  const theme = useTheme();
  const totalPercentage = graphData?.reduce((total, item) => {
    return total + Number(item?.percentage || 0);
  }, 0);

  const colorsTheme = [
    "#4285F4", // Blue
    "#AA46BB", // Purple
    "#0F9D58", // Green
    "#DB4437", // Red
    "#FF6F00", // Orange
    "#A5733C", // Brown
    "#FFCC00", // Yellow
    "#FF00FF", // Pink
    "#00C3E3", // Cyan
  ];

  const remainingPercentage = 100 - totalPercentage;

  const data = {
    labels: [],
    datasets: [
      {
        data: graphData
          ? [
              ...graphData?.map((item) => Number(item?.percentage)),
              remainingPercentage > 0 && remainingPercentage < 100
                ? remainingPercentage
                : null,
            ].filter(Boolean) // filter out null values
          : [],
        backgroundColor: [...colorsTheme],
        borderColor: ["#ffffff"],
        borderWidth: graphData?.length === 1 && totalPercentage === 100 ? 0 : 1.13,
      },
    ],
  };

  const options = {
    cutout: "40%", // Adjust the size of the center hole
    responsive: true,
    width: 330,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: false,
      datalabels: {
        color: "#fff",
        formatter: (value) => value != null && value != 0 && value > 5 ?`${value}%` : '',
        font: {
          size: 10,
          weight: "bold",
        },
        align: "center",
        anchor: "center",
      },
    },
  };

  return (
    <div
      style={{
        width: width,
        height: height,
        marginInline: "auto",
      }}
    >
      {totalPercentage > 0 ? (
        <>
          <div style={{ maxWidth: chartMaxWidth, marginInline: "auto" }}>
            <Doughnut data={data} options={options} />
          </div>
          <PerfectScrollbar
          containerRef={containerRef}
            style={{ marginBlock: "1rem", maxHeight: "-webkit-fill-available" }}
          >
            <Grid
              container
              xs={12}
              width={width}
              sx={{
                border: `1px solid ${theme?.palette?.customColors?.b75}`,
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              {graphData && (totalPercentage < 100 ? [...graphData, {name: "Others", percentage: 100 - totalPercentage}] : [...graphData])?.map(({ name, percentage }, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{
                      padding: "12px 16px",
                      backgroundColor:
                        index % 2 == 0 ? theme?.palette?.customColors?.b50 : "",
                    }}
                  >
                    <Typography>{name}</Typography>
                    <Typography color={colorsTheme[index]}>
                      {percentage}%
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </PerfectScrollbar>
        </>
      ) : (
        <Grid
          display={"grid"}
          sx={{ placeItems: "center", height: "calc(100vh - 5rem)" }}
        >
          Fill The Fund Allocation to View Graph
        </Grid>
      )}
    </div>
  );
};

export default PieChart;
