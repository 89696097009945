import { useTheme } from '@emotion/react'
import { Grid ,Box, Typography, Tooltip, IconButton, Divider } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React, { useState } from 'react'
import CustomLabel from 'ui-components/CustomLabel'
import CustomUrlField from 'ui-components/CustomUrlField'
import CustomValidationText from 'ui-components/CustomValidationText'
import CustomInputField from 'ui-components/formik/CustomInputField'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
const referral_types = [
    {
        value: "Friends",
        label: "Friends",
    },
    {
        value: "Incubator",
        label: "Incubator",
    },
    {
        value: "LinkedIn",
        label: "LinkedIn",
    },
    {
        value: "Google",
        label: "Google",
    },
    {
        value: "Other",
        label: "Other",
    },
];
const ReferralDetails = () => {
    const formik =useFormikContext()
    const theme = useTheme()
  return (
    <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
    <CustomLabel>SELECT REFERRAL MEDIUM  {formik.errors.referral_type && formik.touched.referral_type && (
        <CustomValidationText value={formik.errors.referral_type} style={{ textTransform: 'none' }} />
    )}</CustomLabel>
    <Grid
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        item
        container
        xs={12}
        gap={"1rem"}
    >
        {referral_types?.map((referral, index) => {
            return (
                <Grid
                    role={"button"}
                    item
                    xs={12}
                    sm={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.75}
                    md={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.65}
                    lg={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.8}
                    onClick={() => {
                        formik.setFieldValue(
                            "referral_type",
                            referral?.value
                        );
                    }}
                    sx={{
                        background:
                            formik.values.referral_type ==
                                referral?.value
                                ? theme?.palette?.customColors?.y50
                                : theme?.palette?.customColors?.whiteBG,
                        padding: "12px 16px",
                        height: 55,
                        borderRadius: "8px",
                        display: "grid",
                        placeItems: "center",
                        border: `1px solid ${formik.values.referral_type ==
                            referral?.value
                            ? theme?.palette?.customColors?.y300
                            : theme?.palette?.customColors?.g75
                            }`,
                        boxShadow: "0px 1px 2px 0px #1018280D",
                        cursor: "pointer",
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        color={theme.palette.customColors.g200}
                        fontSize="0.875rem"
                    >
                        {referral?.value}
                    </Typography>
                </Grid>
            );
        })}
    </Grid>
    {formik.values.referral_type === "Other" && <Grid item xs={12} display={"flex"} gap={0} marginTop={2}>
        <CustomInputField value={formik.values.referral_name} name="referral_name" label={"ENTER REFERRER NAME"} placeholder="Enter referrer name" onChange={(e) => formik.setFieldValue("referral_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} />
    </Grid>}
</Grid>
  )
}

export default ReferralDetails
