import React from "react";
import ProfileInformation from "./ProfileInformation";
import StartupInformation from "./StartupInformation";
import { Form, FormikProvider, useFormik } from "formik";
import { AddStartup, FetchAllCountries, FetchAllSectors, FetchStartupDetails } from "../../../api";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import CustomToast from "../../../ui-components/CustomToast";
import Wrapper from "../../../ui-components/Wrapper";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useTheme } from "@emotion/react";




const countryCodes = [
  { code: "+1", label: "US" },
  { code: "+91", label: "IN" },
];

const Onboarding = () => {

  const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: '' })
  const { data: startupDetailsData, isRefetching: isStartupDetailsRefetching, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchStartupDetails()
  const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
  // const [formationDate, setFormationDate] = React.useState(moment());
  // const [formationError, setFormationError] = React.useState(null);
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [searchParams, setSearchParams] = useSearchParams()
  const [progress, setProgress] = React.useState(1);
  const [currentStep, setCurrentStep] = React.useState(Number(stepIndex) || 1);
  React.useEffect(() => {
    if (startupDetailsData?.data?.step && currentStep === 1) {
      setTimeout(() => {
        setSearchParams({ page: Number(startupDetailsData?.data?.step) });
        setCurrentStep(Number(startupDetailsData?.data?.step))
      }, 100)
    }
  }, [startupDetailsData?.data])
  // console.log(Number(startupDetailsData?.data?.step),"Number(startupDetailsData?.data?.step)", startupDetailsData?.data?.step)
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    mutateAsync: AddStartupAsync,
    status: AddStartupStatus,
    error: AddStartupError,
    isLoading: isUpdating,
    data: AddStartupResponse,
  } = AddStartup({ setProgress });

  const validationObj =
    currentStep == 1
      ? {
        first_name: Yup.string("Enter First Name")
          .required("First name is required")
          .trim(),
        last_name: Yup.string("Enter Last Name")
          .required("Last name is required")
          .trim(),
        phone_code: Yup.string("Enter Phone Code")
          .required("Phone code is required")
          .trim(),
        mobile_number: Yup.string("Enter Mobile Number")
          .min(5, 'Mobile number must be at least 5 characters long')
          .max(15, 'Mobile number cannot be more than 15 characters long').required("Mobile number is required"),
      }
      : currentStep == 2
        ? {
          country_id:
            Yup.string("Select a Country")
              .matches(/^[a-fA-F0-9]{24}$/, 'Country is required').required("Country is required")
              .trim(),
        }
        : currentStep == 3
          ? {
            city_id: Yup.string("Select a City").matches(/^[a-fA-F0-9]{24}$/, 'City is required')
              .required("City is required")
              .trim(),
            state_id: Yup.string("Select a State").matches(/^[a-fA-F0-9]{24}$/, 'State is required')
              .required("State is required")
              .trim(),
          }
          : currentStep == 4 ? {
            stage_id: Yup.string().required("Startup stage is required").min(1),
            startup_name: Yup.string("Enter Startup Name")
              .required("Startup name is required")
              .trim(),
            raised_round: Yup.boolean("This field is required").typeError("This field is required")
              .test("", "This field is required", (value) => {
                return value !== null;
              }).required("This field is required")
          } : currentStep == 5
            ? {
              // startup_logo: Yup.mixed()
              //   .test("file-upload", "Please upload a startup logo", (value) => {
              //     return value !== null;
              //   })
              // .required("Startup logo is required"),
              // startup_name: Yup.string("Enter Startup Name")
              //   .required("Startup name is required")
              //   .trim(),
              // entity_name: Yup.string("Enter Legal Entity Name")
              // .required("Legal entity name is required")
              // .trim(),
              // formation_date: Yup.string("Enter Formation Date")
              //   // .required("Formation date is required")
              //   .trim(),
            }
            : currentStep == 6
              ? {
                category_id: Yup.string().required("Startup category is required"),
                sector_id: Yup.string().required('Startup sector is required').matches(/^[a-fA-F0-9]{24}$/, 'Startup sector is required')
              }
              : currentStep == 7
                ? {
                  pitch_deck: Yup.mixed()
                    .test("file-upload", "Please upload pitch deck", (value) => {
                      return value !== null;
                    })
                    .test("file-size", "File size should not exceed 5MB", (value) => {
                      if (value && typeof value === 'object') {
                        return value.size <= 50 * 1024 * 1024; // 100MB in bytes
                      }
                      return true; // If value is not an object, skip size validation
                    })
                    .required("Pitch deck is required"),
                  round_name: Yup.string().required("Round name is required"),
                  round_type: Yup.string().required("Round type is required"),
                  total_round_size: Yup.string().required("Total round size is required"),
                  currency_id: Yup.string().required("Please select currency"),
                }
                : currentStep == 8
                  ? {
                    elevator_pitch: Yup.mixed()
                      .test("file-size", "File size should not exceed 100MB", (value) => {
                        if (value && typeof value === 'object') {
                          return value.size <= 100 * 1024 * 1024; // 100MB in bytes
                        }
                        return true; // If value is not an object, skip size validation
                      }).nullable(),
                    problem_solving: Yup.string("Enter problem statement")
                      .required("Problem statement is required")
                      .trim(),
                    description: Yup.string().required('Startup overview is required')
                      .test(
                        'richText-not-empty',
                        'Startup overview is required',
                        value => {
                          // Check if the value is empty or just a new line
                          const strippedValue = value.replace(/<[^>]+>/g, '').trim();
                          return strippedValue !== '';
                        }
                      )
                  }
                  : currentStep == 9
                    ? {
                      website_url: Yup.string("Enter WEBSITE URL")
                        .required("Website url is required")
                        .trim(),
                      linkedin_url: Yup.string("Enter a LinkedIn URL")
                        .trim(),
                    }
                    :
                    currentStep == 10
                      ? {
                        founder: Yup.object().shape({
                          name: Yup.string().required("Founder name is required"),
                          bio: Yup.string().required("Founder bio is required"),
                          title: Yup.string().required("Founder title is required"),
                          image: Yup.mixed()
                            .test("file-upload", "Please upload founder pic", (value) => {
                              return value !== null;
                            })
                            .required("Founder photo is required"),
                          linkedin_url: Yup.string().required("Founder linkedin url is required")
                        })
                      } : currentStep == 11
                        ? {
                          coFoundersArray: Yup.array().of(Yup.object().shape({
                            name: Yup.string().required("Co-founder name is required"),
                            linkedin_url: Yup.string().required("Co-founder linkedin url is required"),
                            title: Yup.string().required("Co-Founder title is required"),
                            bio: Yup.string().required("Co-Founder bio is required"),
                            // image: Yup.mixed()
                            //   .test("file-upload", "Please upload co-founder image", (value) => {
                            //     return value !== null;
                            //   })
                            //   .required("Co-founder Image is required"),
                            edit: Yup.boolean().notRequired(),
                          }))
                        } : currentStep == 12 ? {
                          referral_type: Yup.string("Select referral medium").required("Referral medium is required"),

                        } : {};

  const validationSchema = Yup.object({ ...validationObj });
  const userDetails = Cookies.get("userDetails") ? JSON.parse(Cookies.get("userDetails")) : {}
  const formik = useFormik({
    initialValues: !isStartupDetailsLoading && !isLoadingCountries && startupDetailsData ? { ...userDetails, ...startupDetailsData?.data } : {
      // step 1
      first_name: "",
      last_name: "",
      mobile_number: "",
      phone_code: "91",
      country_id: null,
      state_id: null,
      city_id: null,
      linkedin_url: "",

      // step 2
      startup_logo: null,
      startup_name: "",
      entity_name: "",
      formation_date: null,
      stage_id: "",
      category_id: "",
      sector_id: "",
      pitch_deck: null,
      elevator_pitch: null,
      problem_solving: "",
      website_url: "",
      founder: {
        name: "",
        bio: "",
        title: "",
        image: null,
        linkedin_url: ""
      },
      coFoundersArray: [
        {
          name: "",
          title: "",
          bio: "",
          image: null,
          linkedin_url: "",
          edit: true,
        },
      ],
      referral_type: "",
      referral_name: "",
      round_name: "",
      round_type: "",
      total_round_size: '',
      currency_id: '',
      step: 1,
      ...userDetails
    },
    validationSchema: validationSchema,
    // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();

      switch (currentStep) {
        case 1:
          formData.append("first_name", values.first_name)
          formData.append("last_name", values.last_name);
          formData.append("mobile_number", values?.mobile_number);
          formData.append("phone_code", values?.phone_code);
          break;

        case 2:
          formData.append("country_id", values.country_id);
          break;

        case 3:
          formData.append("state_id", values.state_id);
          formData.append("city_id", values.city_id);
          break;

        case 4:
          formData.append("startup_name", values.startup_name);
          formData.append("stage_id", values.stage_id);
          formData.append("raised_round", values.raised_round);
          break;

        case 5:
          if (typeof values.startup_logo != 'string') {
            formData.append("startup_logo", values.startup_logo);
          }
          // formData.append("startup_name", values.startup_name);
          if (values?.entity_name != "") { formData.append("entity_name", values.entity_name); }
          // formData.append("formation_date", formationDate?.toISOString());
          if (values?.formation_date != null) { formData.append("formation_date", moment(values.formation_date)?.toISOString()) };
          break;

        case 6:
          // values.sector_id?.length > 0 && values.sector_id?.forEach((current, index) => {
          //   formData.append("sector_id", current);
          // })
          // formData.append("startup_name", values.startup_name);
          formData.append("category_id", values.category_id);
          formData.append("sector_id", [values.sector_id])

          break;

        case 7:
          if (typeof values.pitch_deck != 'string') {
            formData.append("pitch_deck", values.pitch_deck);
          }
          // AddStartupResponse?.data?.data?.round_info?._id && formData.append("round_id", AddStartupResponse?.data?.data?.round_info?._id);
          formData.append("round_name", values.round_name);
          formData.append("round_type", values.round_type);
          formData.append("total_round_size", values.total_round_size);
          formData.append("currency_id", values.currency_id);
          break;

        case 8:
          if (typeof values.elevator_pitch != 'string') { formData.append("elevator_pitch", values.elevator_pitch); }
          formData.append("problem_solving", values.problem_solving);
          formData.append("description", values.description);
          break;

        case 9:
          formData.append("website_url", values.website_url);
          formData.append("linkedin_url", values.linkedin_url);
          break;

        case 10:
          formData.append("founder[name]", values?.founder?.name)
          formData.append("founder[bio]", values?.founder?.bio)
          formData.append("founder[title]", values?.founder?.title)
          // console.log(values?.founder,typeof values?.founder?.image, typeof values?.founder?.founder_image,"typeof values.founder_logo")
          // if (typeof values?.founder?.image === 'object') {
          formData.append("founder_logo", values?.founder?.image);
          // }
          // else if (typeof values?.founder?.founder_image == "string") {
          //   formData.append("founder[founder_image]", values?.founder?.founder_image)
          // }
          // else{

          // }
          formData.append("founder[linkedin_url]", values?.founder?.linkedin_url)
          break;

        case 11:

          values.coFoundersArray?.forEach((current, index) => {
            if (current?.name != "") {
              if (current?.image?.size) {
                formData.append(`cofounder${index}`, current?.image);
              } else {
                formData.append(`cofounder[${index}][cofounder_image]`, current?.image_name);
              }
              formData.append(`cofounder[${index}][name]`, current?.name);
              formData.append(`cofounder[${index}][title]`, current?.title);
              formData.append(`cofounder[${index}][bio]`, current?.bio);
              formData.append(
                `cofounder[${index}][linkedin_url]`,
                current?.linkedin_url
              );
            }
          });
          break;

        case 12:
          formData.append("referral_type", values?.referral_type)
          formData.append("referral_name", values?.referral_name)
          formData.append("is_profile_completed", true)
          break;
        default:
          break;
      }

      if (currentStep >= values?.step || values?.step == undefined) {
        formData.append('step', currentStep)
      }
      // if (currentStep >= 4 ){formData.append("startup_name", values.startup_name);}

      AddStartupAsync(formData).catch(err => {
        // console.log(err?.response?.data?.message, "response herer")

        toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
          duration: 4000,
          position: "top-right",
          style: {
            padding: "15px 30px",
          },
        });
      });

      // console.log(values, "values");
      // setCurrentStep(currentStep + 1)
    },
  });

  // {console.log(AddStartupResponse?.data?.data?.round_info?._id,"rrgg")}

  React.useEffect(() => {
    if (AddStartupResponse && AddStartupResponse?.data?.statusCode == 200) {
      if (currentStep == 12) {
        // console.log(AddStartupResponse,"res")
        Cookies.set("is_profile_complete", AddStartupResponse?.data?.data?.is_profile_completed);
        navigate("/welcome");
      }
      else if (currentStep === 3) {
        navigate("/thank-you")
      }
      else {
        // refetchStartupDetails()
        setCurrentStep(currentStep + 1);
        setSearchParams({ page: currentStep + 1 });
      }
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [AddStartupResponse?.data?.statusCode]);

  return (
    <>
      <Modal open={isStartupDetailsLoading || isLoadingCountries || isLoadingSectors}>
        <Wrapper className="skeleton" style={{
          display: 'grid', height: '100vh',
          width: "100vw",
          placeItems: 'center'
        }}>
          {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
        </Wrapper>
      </Modal>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey && currentStep != 3) {
            formik.handleSubmit();
          }
        }} style={{ height: '100%', display: 'grid', alignContent: 'space-between' }}>
          {/* // style={{ maxHeight: "calc(100vh - 150px)", overflow: 'hidden' }}> */}
          {currentStep <= 3 ?
            <ProfileInformation
              progress={progress}
              setProgress={setProgress}
              formik={formik}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              isLoading={isStartupDetailsLoading}
              isLoadingDetails={isStartupDetailsLoading}
              isUpdating={isUpdating}
            /> :
            <StartupInformation
              progress={progress}
              setProgress={setProgress}
              formik={formik}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              isLoading={isStartupDetailsLoading}
              isUpdating={isUpdating}
            />
          }
        </Form>
      </FormikProvider>
    </>
  );
};

export default Onboarding;
