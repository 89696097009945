import { lazy } from 'react';
import RedirectionScreen from '../views/pages/RedirectionScreen';
import WelcomeScreen from '../views/dashboard/ThankYouScreen';
import MainRoutes from './MainRoutes';
import StartupOnboarding from '../views/profile/onboarding';
import UploadPitchDeck from '../views/profile/onboarding/UploadPitchDeck';
import LogoutScreen from 'views/pages/LogoutScreen';
import OnboardingLayout from 'layout/MainContent/OnboardingLayout';
import { Outlet } from 'react-router-dom';

// // login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const CreatProfileRoutes = {
    path: '',
    // element: <></>,
    children: [
        {
            path: '',
            element: <OnboardingLayout> <Outlet /></OnboardingLayout>,
            children: [
                {
                    path: "",
                    element: <StartupOnboarding />
                },
                {
                    path: "onboarding",
                    element: <StartupOnboarding />
                },
                {
                    path: "upload-pitch-deck",
                    element: <UploadPitchDeck />
                },
                {
                    path: "*",
                    element: <StartupOnboarding />
                },
                {
                    path: '/logout',
                    element: <LogoutScreen />,
                }
                // { ...MainRoutes }
            ]
        },
        {
            path: '/thank-you',
            element: <RedirectionScreen />,
        },
    ]
};

export default CreatProfileRoutes;
