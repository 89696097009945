import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utilities/VerifyToken";
import { useDispatch as dispatch } from "react-redux";
import { SET_STARTUP_DETAILS } from "../store/actions";

export const AddStartup = ({ setProgress }) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/startups`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                onUploadProgress: (data) => {
                    setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const UploadPitchDeckOnboarding = () => {
    return useMutation(
        ({ payload, startup_id }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/upload-pitch-deck`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupDetails = (type = "") => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch' + type],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startups/startup-detail`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data;
            // console.log(data.data.data,'datatatatat')
            // console.log(savedData?.round_info?.raised_round)
            const savedValues = {
                // step 1
                first_name: savedData?.first_name ? String(savedData?.first_name) : '',
                last_name: savedData?.last_name ? String(savedData?.last_name) : '',
                mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                country_id: savedData?.country_id ? savedData?.country_id : null,
                state_id: savedData?.state_id ? savedData?.state_id : null,
                city_id: savedData?.city_id ? savedData?.city_id : null,
                linkedin_url: savedData?.linkedin_url ? String(savedData?.linkedin_url)?.startsWith('https://') ? String(savedData?.linkedin_url)?.slice(8) : String(savedData?.linkedin_url) : '',
                // step 2
                // startup_logo: ("http://192.168.55.19:3001/assets/project_image/startup/" + savedData?.logo_url) || null,
                // startup_logo: savedData?.logo_url ? (savedData?.bucket_path + savedData?.logo_url) : null,
                startup_logo: (savedData?.startup_image),
                startup_name: savedData?.startup_name ? savedData?.startup_name : "",
                entity_name: savedData?.entity_name ? savedData?.entity_name : "",
                formation_date: savedData?.formation_date ? savedData?.formation_date : null,
                stage_id: savedData?.stage_id ? savedData?.stage_id : "",
                raised_round: savedData?.round_info?.raised_round,
                sector_id: savedData?.sector_id ? String(savedData?.sector_id) : "",
                category_id: savedData?.category_id ? savedData?.category_id : "",
                // sector_ids: savedData?.sector_ids ? savedData?.sector_ids : [],
                pitch_deck: savedData?.pitch_deck ? savedData?.pitch_deck : null,
                pitch_deck_url: savedData?.pitch_deck_url ? savedData?.pitch_deck_url : null,
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || "",
                description: savedData?.description || '',
                elevator_pitch: savedData?.elevator_pitch ? savedData?.elevator_pitch : null,
                problem_solving: savedData?.problem_solving || "",
                website_url: savedData?.website_url ? String(savedData?.website_url)?.startsWith('https://') ? String(savedData?.website_url)?.slice(8) : String(savedData?.website_url) : '',
                coFoundersArray:
                    (savedData?.cofounder_image
                        ?
                        savedData?.cofounder_image?.length > 0
                            ? savedData?.cofounder_image?.map(item => (
                                {
                                    edit: true,
                                    name: item?.name || '',
                                    title: item?.title || '',
                                    bio: item?.bio || '',
                                    linkedin_url: item?.linkedin_url ? String(item?.linkedin_url)?.startsWith('https://') ? String(item?.linkedin_url)?.slice(8) : String(item?.linkedin_url) : '',
                                    image: item?.cofounder_image_url ? item?.cofounder_image_url : null,
                                    image_name: item?.cofounder_image ? item?.cofounder_image : null,
                                }
                            )) : [
                                {
                                    name: "",
                                    title: "",
                                    bio: '',
                                    linkedin_url: "",
                                    image: null,
                                    edit: true,
                                },
                            ] : [
                            {
                                name: "",
                                title: "",
                                bio: '',
                                linkedin_url: "",
                                image: null,
                                edit: true,
                            },
                        ]),
                referral_type: savedData?.referral_type || '',
                referral_name: savedData?.referral_name || '',
                founder:
                    savedData?.founder
                        ? (
                            savedData?.founder?.length > 0
                                ?
                                {
                                    name: savedData?.founder[0]?.name || '',
                                    bio: savedData?.founder[0]?.bio || '',
                                    title: savedData?.founder[0]?.title || '',
                                    linkedin_url: savedData?.founder[0]?.linkedin_url ? String(savedData?.founder[0]?.linkedin_url)?.startsWith('https://') ? String(savedData?.founder[0]?.linkedin_url)?.slice(8) : String(savedData?.founder[0]?.linkedin_url) : '',
                                    image: savedData?.founder_image ? savedData?.founder_image : null,
                                    founder_image: savedData?.founder_image ? savedData?.founder_image : null
                                } : {
                                    name: "",
                                    bio: "",
                                    title: "",
                                    linkedin_url: "",
                                    image: null,

                                }
                        ) : {
                            name: "",
                            bio: "",
                            title: "",
                            linkedin_url: "",
                            image: null,

                        },
                step: savedData?.step || 1,
                _id: savedData?._id,
                document_base_url: savedData?.document_base_url,
                backrr_base_url: savedData?.backrr_base_url,
                round_name: savedData?.round_info?.round_name || '',
                round_type: savedData?.round_info?.round_type || "",
                round_id: savedData?.round_info?._id || '',
                total_round_size: savedData?.round_info?.total_round_size || '',
                currency_id: savedData?.round_info?.currency_id || '',
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retryDelay: 0,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}




export const FetchViewStartupDetails = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch-view-view'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startups/startup-profile-details`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            // console.log(data.data.data[0], 'datatatatat')
            const savedValues = {
                // step 1
                first_name: savedData?.first_name ? String(savedData?.first_name) : '',
                last_name: savedData?.last_name ? String(savedData?.last_name) : '',
                mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                country: savedData?.country ? savedData?.country : null,
                state: savedData?.state ? savedData?.state : null,
                city: savedData?.city ? savedData?.city : null,
                linkedin_url: savedData?.linkedin_url ? String(savedData?.linkedin_url)?.startsWith('https://') ? String(savedData?.linkedin_url)?.slice(8) : String(savedData?.linkedin_url) : '',
                // step 2
                // startup_logo: ("http://192.168.55.19:3001/assets/project_image/startup/" + savedData?.logo_url) || null,
                // startup_logo: savedData?.logo_url ? (savedData?.bucket_path + savedData?.logo_url) : null,
                startup_logo: (savedData?.startup_logo_path),
                startup_name: savedData?.startup_name ? savedData?.startup_name : "",
                entity_name: savedData?.entity_name ? savedData?.entity_name : "",
                formation_date: savedData?.formation_date ? savedData?.formation_date : null,
                formatted_formation_date: savedData?.formatted_formation_date ? savedData?.formatted_formation_date : null,
                sector_info: savedData?.sector_info ? savedData?.sector_info : "",
                raised_round: savedData?.raised_round ? savedData?.raised_round : null,
                stage_info: savedData?.stage_info ? savedData?.stage_info : "",
                category_info: savedData?.category_info ? savedData?.category_info : "",
                // sector_ids: savedData?.sector_ids ? savedData?.sector_ids : [],
                pitch_deck_path: savedData?.pitch_deck_path ? savedData?.pitch_deck_path : null,
                pitch_deck_thumbnail_path: savedData?.pitch_deck_thumbnail_path ? savedData?.pitch_deck_thumbnail_path : null,
                pitch_deck_url: savedData?.pitch_deck_url ? savedData?.pitch_deck_url : null,
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || "",
                description: savedData?.description || '',
                elevator_pitch_path: savedData?.elevator_pitch_path ? savedData?.elevator_pitch_path : null,
                problem_solving: savedData?.problem_solving || "",
                website_url: savedData?.website_url ? String(savedData?.website_url)?.startsWith('https://') ? String(savedData?.website_url)?.slice(8) : String(savedData?.website_url) : '',

                coFoundersArray:
                    savedData?.cofounder && savedData?.cofounder?.length > 0 ? savedData?.cofounder?.map(item => (
                        {
                            edit: true,
                            name: item?.name || '',
                            title: item?.title || '',
                            bio: item?.bio || '',
                            linkedin_url: item?.linkedin_url ? String(item?.linkedin_url) : '',
                            image: item?.cofounder_image_path ? item?.cofounder_image_path : null,
                            image_name: item?.cofounder_image ? item?.cofounder_image : null,
                        }
                    )) : [],
                referral_type: savedData?.referral_type || '',
                referral_name: savedData?.referral_name || '',
                founder: savedData?.founder && savedData?.founder?.length > 0 ? {
                    name: savedData?.founder[0]?.name || '',
                    bio: savedData?.founder[0]?.bio || '',
                    title: savedData?.founder[0]?.title || '',
                    linkedin_url: savedData?.founder[0]?.linkedin_url ? savedData?.founder[0]?.linkedin_url : '',
                    image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null,
                    founder_image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null
                } : null,
                step: savedData?.step || 1,
                _id: savedData?._id,
                document_base_url: savedData?.document_base_url,
                backrr_base_url: savedData?.backrr_base_url,
                round_name: savedData?.round_info?.round_name,
                round_type: savedData?.round_info?.round_type,
                round_id: savedData?.round_info?._id,
                raised_round: savedData?.round_info?.raised_round,
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retryDelay: 0,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

// export const FetchAllStartupFeedbacks = () => {
//     return useMutation(
//         (payload) => {
//             let page = payload?.page ?? 1;
//             let search = payload?.search ?? '';
//             let limit = payload?.limit ?? 10;
//             let sort = payload?.sort ?? -1;
//             // console.log(payload, "payload"); // Removed excessive logging
//             // &searchText=${search}
//             return axios.get(`${process.env.REACT_APP_BASE_URL}/startups/feedbacks/${payload?.startup_id}?page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''
//                 }`, {
//                 headers: {
//                     Authorization: `Bearer ${Cookies.get('jwt')}`
//                 }
//             });
//         },
//         {
//             cacheTime: 2000,
//             retry: (failureCount, error) => RetryOnError(failureCount, error),
//             onError: (error) => verifyToken(error)
//         }
//     );
// };

export const FetchAllStartupFeedbacks = ({ id : startup_id, value, page = 1, limit = 10, sort = -1 }) => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-for-all-feedbacks-roundss-fetch' + startup_id + value, startup_id, value, page, limit, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startups/feedbacks/${startup_id}?page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return {data: data?.data?.data, count: data?.data?.count, limit: Number(data?.data?.limit)}
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllStartupProfileViews = ({ id : startup_id, value, page = 1, limit = 10, sort = -1 }) => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-for-all-roundss-viewssssss-fetch' + startup_id + value, startup_id, value, page, limit, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startups/profile-view/${startup_id}?page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return {data: data?.data?.data, count: data?.data?.count, limit: Number(data?.data?.limit)}
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

// export const FetchAllStartupProfileViews = () => {
//     return useMutation(
//         (payload) => {
//             let page = payload?.page ?? 1;
//             let search = payload?.search ?? '';
//             let limit = payload?.limit ?? 10;
//             let sort = payload?.sort ?? -1;
//             // console.log(payload, "payload"); // Removed excessive logging
//             // &searchText=${search}
//             return axios.get(`${process.env.REACT_APP_BASE_URL}/startups/profile-view/${payload?.startup_id}?page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}`, {
//                 headers: {
//                     Authorization: `Bearer ${Cookies.get('jwt')}`
//                 }
//             });
//         },
//         {
//             cacheTime: 2000,
//             retry: (failureCount, error) => RetryOnError(failureCount, error),
//             onError: (error) => verifyToken(error)
//         }
//     );
// };