import { Box, FormControlLabel, Grid,RadioGroup,Skeleton,Typography,useMediaQuery } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { AddStartup, FetchAllCategories, FetchAllCurrencyLists, FetchAllRoundTypes, FetchAllSectors, FetchAllStages, FetchAllSubCategories } from 'api'
import CustomLabel from 'ui-components/CustomLabel'
import { useTheme } from '@emotion/react'
import CustomValidationText from 'ui-components/CustomValidationText'
import { LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, StorefrontOutlined } from '@mui/icons-material'
import CustomAutoComplete from 'ui-components/CustomAutoComplete'


const SelectCategory = ({isLoading}) => {
    const formik = useFormikContext()

    const [selectedCategory, setSelectedCategory] = React.useState("");
    const [currentSubCategory, setCurrentSubCategory] = React.useState('');
    const [searchSubCategory, setSearchSubCategory] = React.useState('');
    const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })

    // Effect to set selected category based on formik values
    React.useEffect(() => {
        if (!isLoading && !isLoadingCategories && formik?.values && Array.isArray(startup_categories)) {
            const category = startup_categories.find(item => item?._id === formik?.values?.category_id);
            if (category) {
                setSelectedCategory(category.name);  // Or whichever property you want to use
            }
        }
    }, [isLoading, isLoadingCategories, formik?.values, startup_categories, formik?.values?.category_id]);

    // Effect to refetch subcategories when selectedCategory changes
    // Effect to refetch subcategories when selectedCategory changes
    React.useEffect(() => {
        if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
            refetchSubCategories();
        }
    }, [selectedCategory, isRefetchingCategories || isLoadingCategories]);

    React.useEffect(() => {
        if (formik.values.sector_id && startup_sub_categories) {
            const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sector_id);
            if (matchedSubCategory) {
                setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
            }
        }
    }, [formik.values.sector_id, startup_sub_categories]);

    const handleSearchSubCategoryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSubCategory(value);
        // refetchCities();
    };
    const handleSelectionSubCategoryChange = (event, sector) => {
        // console.log(value, "value")
        formik.setFieldValue("sector_id", sector ? sector?.value : '');
        setCurrentSubCategory(sector)
        event.preventDefault();
    };
    const handleSearchUpdateSubCategory = () => {
        refetchSubCategories();
    }

  return (
    <>
    <Grid container xs={12} gap={0} paddingBottom={0} paddingRight={1}>
            {/* <Grid xs={12} item container style={{ display: 'flex', alignItems: 'flex-start' }}> */}
            <Grid item xs={12}>
                <CustomLabel sx={{ marginTop: "0rem" }}>Select Category</CustomLabel>
            </Grid>
            {/* <Grid item container xs={12} display={"flex"} paddingBottom={{ xs: 1 }} sx={{ justifyContent: 'space-between'}}> */}
            <FieldArray
                name="category_id"
                render={arrayHelpers => (
                    <>
                        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', padding: 0, paddingBottom: 1, marginTop: 0, justifyContent: 'space-between' }} gap={2}>
                            {Array.isArray(startup_categories) && startup_categories?.map((category, index) => {
                                const isSelected = formik?.values?.category_id?.includes(category?._id);
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5.8}
                                        md={5.75}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 0,
                                            background: isSelected ? "#FFFAE6" : "#FFF",
                                            fontSize: "0.875rem",
                                            border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                            borderRadius: "8px",
                                            paddingRight: "1rem",
                                            paddingLeft: "1rem",
                                            paddingBlock: '1.5rem',
                                            height: "2.5rem",
                                            cursor: "pointer",
                                            textTransform: 'none',
                                            color: 'inherit',
                                            // opacity: formik?.values?.category_id?.length >= 1 && !isSelected ? 0.5 : 1, // Dims unselected options when max is reached
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formik.setFieldValue("category_id", category?._id)
                                            setSelectedCategory(category?.name);
                                            formik.setFieldValue("sector_id", '');
                                            setCurrentSubCategory('')
                                            // if (isSelected) {
                                            // arrayHelpers.remove(formik.values?.category_id.indexOf(category?._id));
                                            // } else if (formik.values?.category_id?.length < 1) {
                                            // arrayHelpers.push(category?._id);
                                            // }
                                        }}
                                        key={category?._id}
                                    >
                                        {/* <img
                                    src={sector?.image_url}
                                    rel='preload'
                                    style={{
                                        minHeight: "1rem",
                                        maxHeight: "1rem",
                                        height: "100%",
                                    }}
                                    loading='lazy'
                                /> */}
                                        {/* <i style={{ ...sector?.style }}>

                                            </i> */}

                                        {index === 0 && <MemoryOutlined sx={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />}
                                        {index === 1 && <StorefrontOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}
                                        {index === 2 && <LocalMallOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}
                                        {index === 3 && <MiscellaneousServicesOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}

                                        <span> {category?.name} </span>
                                        {/* <span> {index == 0 ? "Technology  (Product / Software)" : index == 1 ? 'Consumer Brand' : index === 2 ? "Marketplace" : 'Services'} </span> */}
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {formik.errors?.category_id && (
                            <CustomValidationText value={formik.errors?.category_id} style={{ textTransform: 'none' }} />
                        )}
                    </>
                )}
            />
            {/* </Grid> */}
            {/* </Grid> */}
            <Grid item xs={12} mt={1.5}>
                {<CustomAutoComplete
                    options={!isLoadingSubCategories && !isRefetchingSubCategories && Array.isArray(startup_sub_categories) ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id, description: item?.description })) : []}
                    label="Select Sub Sector"
                    name={"sector_id"}
                    noOptionsText={!isLoadingSubCategories && !isRefetchingSubCategories ? "No Options" : "Searching..."}
                    placeholder="Type a sub sector name"
                    icon={null}
                    handleSearchChange={handleSearchSubCategoryChange}
                    handleSelectionChange={handleSelectionSubCategoryChange}
                    search={searchSubCategory}
                    renderOption={(props, option) => (
                        <li {...props} style={{ display: 'grid' }}>
                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                            <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                            {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                        </li>
                    )}
                    value={currentSubCategory}
                    defaultValue={startup_sub_categories?.find(item => item?._id == formik?.values?.sector_id)}
                    loading={isRefetchingSubCategories}
                    handleSearchUpdate={handleSearchUpdateSubCategory}
                />}
            </Grid>
        </Grid>
    </>
  )
}

export default SelectCategory
