import { Avatar, Box, Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { WHITEBG } from '../../../ui-components/CustomButton';
import { ReactComponent as EditIcon } from "../../../assets/rounds_icons/edit_icon.svg";
import PieChart from '../../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { handleDateValues } from '../../../utilities';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ThumbnailGenerator from 'ui-components/ThumbnailGenerator';
import moment from 'moment';
import { ReactComponent as UploadOutlinedIcon } from "../../../assets/upload-cloud-icon-outlined.svg"
import { B400 } from 'ui-components/CustomInputButton';

const PersonDetails = ({ theme, data, index, length }) => {

    const isSmallScreen = (useMediaQuery(theme.breakpoints.down('sm')))[0];
    const isTableScreen = (useMediaQuery(theme.breakpoints.up('sm')));
    return <Grid xs={12} sm={12} sx={{
        padding: '0rem', boxShadow: 'none', borderRadius: "8px",
        // border: `1px solid ${theme.palette.customColors.gray300}`,
        overflow: 'hidden', paddingTop: 1, display: 'flex', flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" }, gap: "1.5rem", 
    }}>
        {data?.image ? <img src={data?.image} style={{ flex: isTableScreen ? 0 : 0.3, minHeight: 100,height: 100, maxHeight: 100, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} /> : <Avatar sx={{ flex: 0.3, width: 100, height: 100, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} />}
        <Box display={"grid"} gap={1} sx={{ placeContent: { xs: "center", sm: "start", md: "center", lg: "start" }, flex: 1 }}>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"flex-start"} marginInline={{ xs: "auto", sm: "0", md: "auto", lg: 0 }} gap={1}>
                {data?.name && <Typography textAlign={{ xs: "center", sm: "start", md: "center", lg: "start" }} sx={{ fontWeight: 600 }}>{data?.name}  {data?.type && `${data?.type}`}</Typography>}
                {data?.linkedin_url && <LinkedInIcon sx={{ fontSize: 20 }} style={{ display: 'inline-block', marginInlineEnd: { xs: "auto", sm: 0, md: 'auto', lg: 0 }, color: `${theme.palette.customColors.indigo600}` }} onClick={() => { window.open(data?.linkedin_url) }} />}
            </Grid>
            {data?.bio && <Typography textAlign={{ xs: "center", sm: "start", md: "center", lg: "start" }} sx={{ marginInline: { xs: "auto", sm: 0, md: "auto", lg: 0 } }}>{data?.bio}</Typography>}
        </Box>
    </Grid>
}

const CardContentList = ({ data = [], style = {} }) => {
    const theme = useTheme();
    return <>
        <Grid
            container
            xs={12}
            sx={{
                border: `1px solid ${theme?.palette?.customColors?.b75}`,
                borderRadius: "8px",
                overflow: "hidden",
                ...style
            }}
        >
            {
                data?.map(({ label, value }, index) => {
                    const isHighlighted = index % 2 === 0;
                    return (

                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                            // alignItems="center"
                            sx={{
                                padding: "12px 16px",
                                gap: '1rem',
                                backgroundColor: isHighlighted ? (theme) => theme.palette.customColors.b50 : 'transparent',
                            }}
                        >
                            <Typography>{label}</Typography>
                            <Typography textAlign={"end"}>{value ? value : '-'}</Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    </>
}


const CardHeader = ({ title, action, actionText, actionStyle = {} }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        {action && <WHITEBG
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                display: "flex",
                gap: '0.5rem',
                paddingInline: '0.5rem 0.875rem',
                ...actionStyle,
            }}
            onClick={action}
        >
            <EditIcon
                style={{
                    width: 20,
                    height: 20,
                }} />
            <Typography>
                {actionText ? actionText : "Edit"}
            </Typography>
        </WHITEBG>}
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "" }) => {
    return (
        <>
            {htmlContent && htmlContent?.length > 0 ?
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} className='rich-text' style={{ color: color, textAlign: 'justify' }} />
                : "-"
            }
        </>
    );
};

const ViewDetails = ({ data: startupDetail, round_id }) => {
    // console.log("startupDetail", startupDetail)
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    let founders = startupDetail?.founder != null ? [
        startupDetail?.founder, ...startupDetail?.coFoundersArray
    ] : startupDetail?.coFoundersArray

    const startupInformation = [
        { label: "Startup Name", value: startupDetail?.startup_name ? startupDetail?.startup_name : '-' },
        { label: "Legal Entity Name", value: startupDetail?.entity_name ? startupDetail?.entity_name : '-' },
        { label: "Formation Date", value: startupDetail?.formation_date != null && startupDetail?.formation_date ? startupDetail?.formatted_formation_date : "-" },
        { label: "Stage", value: startupDetail?.stage_info?.name ? startupDetail?.stage_info?.name : "-" },
        { label: "Sector", value: `${startupDetail?.category_info?.name ? startupDetail?.category_info?.name : "-"} ${startupDetail?.sector_info[0]?.sub_category ? " (" + startupDetail?.sector_info[0]?.sub_category + ")" : ""}` },
    ]

    const contactInformation = [
        { label: "Full Name", value: startupDetail?.first_name + " " + startupDetail?.last_name },
        { label: "Mobile", value: `${startupDetail?.phone_code} - ${startupDetail?.mobile_number}` },
        { label: "Location", value: `${startupDetail?.city}, ${startupDetail?.state}, ${startupDetail?.country}` },
        {
            label: "Website Url", value: <Link target="_blank" to={"https://" + startupDetail?.website_url} variant="body1">
                {startupDetail?.website_url}
            </Link>
        },
        {
            label: "Startup LinkedIn Page", value: <Link target="_blank" to={"https://" + startupDetail?.linkedin_url} variant="body1">
                {startupDetail?.linkedin_url}
            </Link>
        },
    ]
    return (
        <Grid item container xs={12} gap={"1.25rem"} display={"grid"} sx={{ paddingBottom: '2rem', transform: { xs: "scaleX(1)", sm: "scaleX(1)" } }} gridTemplateAreas={isLargeScreen ? `"first aside" "second aside" "third aside" "forth aside" "fifth aside" "sixth aside"` : `"first" "aside" "second" "third" "forth" "fifth" "sixth"`}
            gridTemplateColumns={isLargeScreen ? "2.5fr 1.5fr" : '1fr'} justifyContent={{ xs: "center", md: "space-between" }}>
            <Grid sx={{ gridArea: 'first', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Startup Information"} action={() => { navigate(`/profile/update?page=1`) }} />
                    <Grid xs={12} item container spacing={2} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            {startupDetail?.startup_logo ? <img src={startupDetail?.startup_logo} style={{ height: isLargeScreen ? 150 : 110, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} /> : <Avatar sx={{ flex: 0.3, width: 100, height: 100, aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", margin: 'auto', display: 'flex', border: `1px solid ${theme.palette.customColors.g75}`, alignItems: 'center', justifyContent: 'center' }} />}
                        </Grid><Grid item xs={12} sm={9} md={8} lg={9}>
                            <CardContentList
                                data={startupInformation} />
                        </Grid>
                    </Grid>
                    {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Startup Name</Typography>
                            <Typography>
                                {startupDetail?.startup_name ? startupDetail?.startup_name : '-'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Legal Entity Name</Typography>
                            <Typography>
                                {startupDetail?.entity_name ? startupDetail?.entity_name : '-'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Formation Date</Typography>
                            <Typography>
                                {startupDetail?.formation_date != null && startupDetail?.formation_date ? startupDetail?.formatted_formation_date : "-"}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Stage</Typography>
                            <Typography>
                                {startupDetail?.stage_info?.name ? startupDetail?.stage_info?.name : "-"}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Sector</Typography>
                            <Typography>
                                {startupDetail?.category_info?.name ? startupDetail?.category_info?.name : "-"}
                                {startupDetail?.sector_info[0]?.sub_category ? " (" + startupDetail?.sector_info[0]?.sub_category + ")" : ""}
                            </Typography>
                        </Grid>
                    </Grid> */}
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'aside', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                {founders?.length > 0 && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <Grid item xs={12} paddingRight={0} marginTop={0}>
                        <Card sx={{ padding: '1rem 0', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, border: `0px solid ${theme.palette.customColors.g75}` }}>
                            <CardHeader title={"Founders"} action={() => { navigate(`/profile/update?page=3`) }} />
                            <Grid container xs={12} gap={1} alignItems={"flex-start"}>
                                {
                                    founders?.map((person, index) => {
                                        return <PersonDetails theme={theme} data={person} key={index} index={index} length={founders?.length} />
                                    })
                                }
                            </Grid>
                        </Card>
                    </Grid>
                </Card>}
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginTop: founders?.length > 0 ? "1.25rem" : 0, paddingLeft: 0 }}>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `0px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        {startupDetail?.elevator_pitch_path ?
                            <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '0' }}>
                                    <CardHeader title={"Video Pitch"}
                                        action={() => { navigate(`/profile/update?page=2`) }} actionStyle={{ opacity: { xs: 1, sm: 0, md: 1 } }}
                                    // sx={{ fontSize: "1rem" }}
                                    />
                                    <ThumbnailGenerator height={300} type="video" url={startupDetail?.elevator_pitch_path} />
                                </Card>
                            </Grid> : <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '0' }}>
                                    <CardHeader title={"Video Pitch"} action={() => { navigate(`/profile/update?page=2`) }} actionStyle={{ opacity: { xs: 1, sm: 0, md: 1 } }}
                                    // sx={{ fontSize: "1rem" }}
                                    />
                                    <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 284 }}>
                                        <B400
                                            sx={{
                                                background: theme.palette.customColors.indigo700,
                                                padding: "0.625rem 1rem"
                                            }}
                                            startIcon={
                                                <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                                            }
                                            onClick={() => { navigate(`/profile/update?page=2`); }}
                                        >
                                            Upload
                                        </B400>

                                    </Card>
                                </Card>
                            </Grid>}
                        {startupDetail?.pitch_deck_path ?
                            <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={"0"} marginTop={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: 0, }}>
                                    <CardHeader title={"Pitch Deck"} action={() => { navigate(`/profile/update?page=2`) }} actionStyle={{ opacity: { xs: 0, sm: 1, md: 0 } }} />
                                    <ThumbnailGenerator url={startupDetail?.pitch_deck_path} height={300} thumbnail={startupDetail?.pitch_deck_thumbnail_path || null} action={() => window.open(startupDetail?.pitch_deck_path)} />
                                </Card>
                            </Grid> : <Grid item xs={12}
                                sm={6} md={12}
                                paddingLeft={"0 !important"} paddingRight={0}>
                                <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `0px solid ${theme.palette.customColors.g75}`, borderRadius: '15px' }}>
                                    <CardHeader title={"Pitch Deck"} action={() => { navigate(`/profile/update?page=2`) }} actionStyle={{ opacity: { xs: 0, sm: 1, md: 0 } }}
                                    // sx={{ fontSize: "1rem" }}
                                    />
                                    <Card sx={{ display: "grid", placeItems: 'center', padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', paddingTop: 0, paddingRight: 0, border: `1px solid #e8e9ea`, borderRadius: '15px', height: 274 }}>
                                        <B400
                                            sx={{
                                                background: theme.palette.customColors.indigo700,
                                                padding: "0.625rem 1rem"
                                            }}
                                            startIcon={
                                                <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                                            }
                                            onClick={() => { navigate(`/profile/update?page=2`); }}
                                        >
                                            Upload
                                        </B400>
                                    </Card>
                                </Card>
                            </Grid>}
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'second', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Startup Details"} action={() => { navigate(`/profile/update?page=2`) }} />
                    {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Full Name</Typography>
                            <Typography>
                                {startupDetail?.first_name + " " + startupDetail?.last_name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Mobile</Typography>
                            <Typography>
                                {startupDetail?.phone_code} - {startupDetail?.mobile_number}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Location</Typography>
                            <Typography textAlign={"end"}>
                                {startupDetail?.city_id}, {startupDetail?.state_id}, {startupDetail?.country_id}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Website Url</Typography>
                            <Typography>
                                {startupDetail?.website_url}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Startup LinkedIn Page</Typography>
                            <Typography>
                                {startupDetail?.linkedin_url}
                            </Typography>
                        </Grid>
                    </Grid> */}
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                        <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                            What problem are you solving?
                        </Typography>
                        <RichTextDisplay htmlContent={startupDetail?.problem_solving} />
                    </Card>
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                        <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                            Startup Overview
                        </Typography>
                        <RichTextDisplay htmlContent={startupDetail?.description} />
                    </Card>
                </Card>
            </Grid>
            {<Grid sx={{ gridArea: 'third', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Contact Information"} action={() => { navigate(`/profile/update?page=0`) }} />
                    <CardContentList
                        data={contactInformation} />
                    {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Full Name</Typography>
                            <Typography>
                                {startupDetail?.first_name + " " + startupDetail?.last_name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Mobile</Typography>
                            <Typography>
                                {startupDetail?.phone_code} - {startupDetail?.mobile_number}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Location</Typography>
                            <Typography textAlign={"end"}>
                                {startupDetail?.city}, {startupDetail?.state}, {startupDetail?.country}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Website Url</Typography>
                            <Typography>
                                <Link target="_blank" to={"https://" + startupDetail?.website_url} variant="body1">
                                    {startupDetail?.website_url}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Startup LinkedIn Page</Typography>
                            <Typography>
                                <Link target="_blank" to={"https://" + startupDetail?.linkedin_url} variant="body1">
                                    {startupDetail?.linkedin_url}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid> */}
                </Card>
            </Grid>}
            <Grid sx={{ gridArea: 'forth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                {/* <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Current Traction & Runway"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=4`) }} />
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                        <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                            Traction Highlight
                        </Typography>
                        <RichTextDisplay htmlContent={startupDetail?.traction_highlights} color={theme.palette.customColors.g200} />
                    </Card>



                    {startupDetail?.key_metrics?.length > 0 && startupDetail?.key_metrics?.map((metric, idx) => {
                        return <>
                            <Typography fontWeight={500} mt={'0.75rem'} mb={'0.375rem'} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                {metric?.heading}
                            </Typography>
                            <Grid
                                container
                                xs={12}
                                sx={{
                                    border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {metric?.properties?.map((item, index) => {
                                    return <Grid
                                        item
                                        key={index}
                                        xs={12}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            padding: "12px 16px",
                                            backgroundColor:
                                                index % 2 === 0 ? theme?.palette?.customColors?.b50 : '',
                                        }}
                                    >
                                        <Typography>{item?.key}</Typography>
                                        <Typography>
                                            {item?.value}
                                        </Typography>
                                    </Grid>
                                })}
                            </Grid>
                        </>
                    })}
                </Card> */}
            </Grid>
            <Grid sx={{ gridArea: 'fifth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" } }} item>
                {/* <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginLeft: { xs: "1rem", sm: 0 } }}>
                    <CardHeader title={"FAQs"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=6`) }} />
                    {
                        startupDetail?.faqs && startupDetail?.faqs?.length > 0 && startupDetail?.faqs?.map(({ question, answer }, index) => {
                            return <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                                    {question}
                                </Typography>
                                <RichTextDisplay htmlContent={answer} />
                            </Card>
                        })
                    }
                    {
                        startupDetail?.faqs && startupDetail?.faqs?.length === 0 && <>
                            <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} textAlign={"center"}>
                                    No FAQs available
                                </Typography>
                            </Card>
                        </>
                    }
                </Card> */}
            </Grid>
            <Grid sx={{ gridArea: 'sixth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                {/* <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>

                    <CardHeader title={"Exit Strategy & Timeline"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=7`) }} />
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                        <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                            Exit Highlight
                        </Typography>
                        <RichTextDisplay htmlContent={startupDetail?.exit_highlights} color={theme.palette.customColors.g200} />
                    </Card>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Runway Left</Typography>
                            <Typography>
                                {startupDetail?.runway_left > 1 ? startupDetail?.runway_left + " Months" : startupDetail?.runway_left == 1 ? startupDetail?.runway_left + " Month" : "-"}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Monthly Net Burn</Typography>
                            <Typography>
                                {startupDetail?.monthly_net_burn_final ? startupDetail?.monthly_net_burn_final : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography fontWeight={500} mt={'0.75rem'} mb={'0.375rem'} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                        Other Details
                    </Typography>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Round Start Date</Typography>
                            <Typography>
                                {handleDateValues(startupDetail?.final_round_start_date, "-")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Round Closing Date</Typography>
                            <Typography>
                                {handleDateValues(startupDetail?.final_round_closing_date, "-")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Round Status</Typography>
                            <Typography>
                                {startupDetail?.round_status_info?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card> */}
            </Grid>
        </Grid>
    )
}

export default ViewDetails