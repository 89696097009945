import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BLUEBORDER,
  B300 as CustomButton,
} from "../../ui-components/CustomButton";
import { Box, Grid, IconButton, Modal, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Wrapper from "../../ui-components/Wrapper";
import CustomBackgroundComponent from "../../ui-components/CustomBackgroundComponent";
import bgImage from "../../assets/login_screen_image.png";
import welcomeImage from "../../assets/Illustration.webp";
import Cookies from "js-cookie"
import { FetchStartupDetails } from "../../api";
import { SET_STARTUP_DETAILS } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { CheckCircle, ContentCopyOutlined, FileCopy } from "@mui/icons-material";

const ThankYouScreen = () => {
  const { data: userData, isLoading: isUserDataLoading } = FetchStartupDetails();
  const dispatch = useDispatch()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  // console.log(userData,"userData")
  const navigate = useNavigate();
  React.useEffect(() => {
    if (userData?.data) dispatch({ type: SET_STARTUP_DETAILS, data: userData?.data });
  }, [isUserDataLoading])

  const [copied, setCopied] = React.useState(false);

  const handleCopyClick = (copyLink) => {
    // Perform the copy action (e.g., copying text to clipboard)
    navigator.clipboard.writeText(copyLink);

    // Change the state to show the clicked (copied) icon
    setCopied(true);

    // Optionally reset the icon back after a delay
    setTimeout(() => setCopied(false), 2000);
  };
  return (
    <Wrapper>
      <Modal open={isUserDataLoading}>
        <Box className="skeleton" height={"100vh"} width={"100%"}></Box>
      </Modal>
      <Grid
        xs={12}
        container
        direction="row"
        justifyContent="center"
        flexWrap={"nowrap"}
        sx={{
          padding: "0.97rem", maxWidth: '1440px !important',
          marginInline: 'auto',
          height: '100vh'
        }}
      >
        <Grid xs={12} sm={12} md={6.6} sx={{ display: 'grid', padding: { xs: "1rem", sm: "2rem", md: "2rem 2rem", lg: "2rem 5rem" }, gap: "2rem", maxHeight: "97vh", placeContent: "center" }}>
          <Grid item xs={12} display={"grid"} sx={{ justifyContent: "center", gap: 2 }}>
            <img
              src={welcomeImage}
              style={{ width: isSmallScreen ? 200 : '100%', maxWidth: isSmallScreen ? "200px" : "400px", height: isSmallScreen ? 150 : "100%", maxHeight: isSmallScreen ? "150px" : "250px", margin: 'auto', marginBottom: 20 }}
              alt="welcomeImage"
            />
            <Typography
              variant='title' sx={{ marginTop: { xs: "12px", md: "1rem" }, fontSize: { xs: "1.5rem", md: "1.75rem" } }} textAlign={"center"}
              height={"min-content"}
            >
              Welcome aboard, {userData?.data?.first_name ? userData?.data?.first_name : ''}!
            </Typography>
            <Typography variant="body1" height={"min-content"} sx={{ textAlign: "center" }}>
              Your pitch deck has been saved successfully.
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              border: "1px solid #ADA2ED",
              background: "#EBE8FB",
              padding: { xs: "1rem", md: "1.5rem" },
              borderRadius: "8px",
              width: "100%",
              maxWidth: { xs: "100%", sm: "65vw" },
              height: "100%",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700, textAlign: { xs: "center", md: 'start' } }}>
              Share Backrr link to investors and get investment interest:
            </Typography>
            <Typography variant="caption2" component={"p"} sx={{ textAlign: { xs: "center", md: 'start' } }} mt={1}>
              <Link target="_blank" to={userData?.data?.document_base_url + "?token=" + Cookies?.get("jwt")} variant="body1">
                {/* {userData?.data?.backrr_base_url} */}
                {/* <br /> */}
                {userData?.data?.document_base_url}
              </Link>
              <Tooltip title={copied ? "Copied to Clipboard" : "Copy"}>
                <IconButton onClick={() => handleCopyClick(userData?.data?.document_base_url)}>
                  {copied ? <FileCopy
                    sx={{ height: 20 }}
                  // sx={{ color: theme.palette.customColors.b300 }}
                  /> : <ContentCopyOutlined sx={{ height: 20 }} />}
                </IconButton>
              </Tooltip>
            </Typography>
            {/* <Typography variant="body1" sx={{ textAlign: { xs: "center", md: 'start' } }}>
              
            </Typography> */}
          </Grid>

          <Grid
            item
            container
            xs={12}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"center"}
            gap={{ xs: 2 }}
            mt={2}
          >
            <Grid xs={12} sm={5} md={5.5} lg={5.65} item>
              <BLUEBORDER
                sx={{
                  textTransform: "none",
                  padding: 2,
                  borderWidth: 1,
                  borderRadius: "8px",
                }}
                type="no usetr"
                onClick={() => {
                  // Cookies.set("is_profile_complete", true)
                  navigate("/");
                }}
                fullWidth
              >
                Go to Dashboard
              </BLUEBORDER>
            </Grid>
            <Grid xs={12} sm={5} md={5.5} lg={5.65} item>
              <CustomButton
                sx={{ padding: 2, borderRadius: "8px" }}
                onClick={() => {
                  // Cookies.set("is_profile_complete", true)
                  navigate("/rounds/edit_round/"+ userData?.data?.round_id);
                }}
                fullWidth
              >
                Update Round
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>

        <CustomBackgroundComponent />
      </Grid>
    </Wrapper>
  );
};

export default ThankYouScreen;
