import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PerfectScrollbar from "react-perfect-scrollbar"
import BreadcrumbHeader from '../../../layout/BreadcrumbsHeader'
import { SECONDARY, WHITEBG } from '../../../ui-components/CustomButton'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../../ui-components/ThemeBGWrapper'
import { FetchRoundViewDetails } from '../../../api'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, CircularProgress, Grid, Tab, Tabs, Typography, useMediaQuery, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { ReactComponent as RoundDetailsIcon } from "../../../assets/rounds_icons/round_details_icon.svg"
import { ReactComponent as RoundInvestorsIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import { ReactComponent as RoundDocumentsIcon } from "../../../assets/rounds_icons/round_documents.svg"
import RoundDetails from './RoundDetails'
import RoundInvestors from './RoundInvestors'
import RoundDocuments from './RoundDocuments'
import { ContentCopyOutlined, FileCopy } from '@mui/icons-material'

import { ReactComponent as InfoIcon } from '../../../assets/help-circle.svg';
import CustomTabs from '../../../ui-components/CustomTabs'
import { containerRef, handleCopyClick } from '../../../utilities'
import RoundInvestorsNew from './RoundInvestorsNew'
import NeedHelpButton from 'common-components/NeedHelpButton'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));




const ViewRound = () => {
    const { id: round_id } = useParams();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();
    const Actions = () => {
        return (
            <>
                <NeedHelpButton />
            </>
        );
    };

    let tabValue = new URLSearchParams(window.location.search).get("page");
    // console.log("tabl",tabValue)

    const [value, setValue] = React.useState(tabValue || "view_details");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const roundTabs = [
        {
            label: "Round Details",
            icon: RoundDetailsIcon,
            value: "view_details",
        },
        // {
        //     label: "Investors",
        //     icon: RoundInvestorsIcon,
        //     value: "investors",
        // },
        {
            label: "Investors",
            icon: RoundInvestorsIcon,
            value: "round_investors",
        },
        {
            label: "Documents",
            icon: RoundDocumentsIcon,
            value: "documents"
        }
        // ,
        // {
        //     label: "Investors Documents",
        //     icon: RoundDetailsIcon,
        //     value: "investors_documents"
        // }
    ]

    const { data: roundDetails, isLoading: isLoadingRoundDetails, refetch: refetchRoundDetails } = FetchRoundViewDetails(round_id)
    // console.log(roundDetails, "roundDetails")
    const [copied, setCopied] = React.useState(false)
    return (
        <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
            <BreadcrumbHeader title={() => <> {isLoadingRoundDetails ? "Loading..." : isSmallScreen ? "View Details" : roundDetails?.round_name ? roundDetails?.round_name : "View Details"} {!isLoadingRoundDetails && <Tooltip title={copied ? "Copied to Clipboard" : "Copy Round Url"}>
                <IconButton onClick={() => handleCopyClick(roundDetails?.backrr_base_url, copied, setCopied)}>
                    {copied ? <FileCopy
                        sx={{ height: 20 }}
                    // sx={{ color: theme.palette.customColors.b300 }}
                    /> : <ContentCopyOutlined sx={{ height: 20 }} />}
                </IconButton>
            </Tooltip>} </>} Actions={Actions} sx={{ paddingBottom: 0 }} titleStyle={isLoadingRoundDetails ?  ({}) : ({ marginTop: "0.4rem" })} />
            {isLoadingRoundDetails ?
                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingTop: "1rem", bgColor: theme.palette.customColors.yellow_bg }}>
                    {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
                    <Box className='skeleton' height={80}>

                    </Box>
                    <Box className='skeleton' height={"calc(100% - 80px)"} marginTop={2}>

                    </Box>
                </Grid>
                : <>


                    <Grid xs={12} container bgColor={theme.palette.customColors.yellow_bg} height={"100%"}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box px={isSmallScreen ? "1rem" : "2rem"} bgColor={theme.palette.customColors.yellow_bg}>
                                    <CustomTabs data={roundTabs} value={value} isSmallScreen={isSmallScreen} className={classes} handleChange={handleChange} key={"Tabs Tabs"} sx={{ paddingTop: '1rem' }} bgColor={theme.palette.customColors.yellow_bg} />
                                </Box>
                                <TabPanel value="view_details" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: "calc(100vh - 210px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <RoundDetails data={roundDetails} round_id={round_id} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="investors" sx={{ paddingInline: 0, height: '100%', paddingTop: '0.5rem' }}>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: "calc(100vh - 180px)", paddingBottom: '0.5rem' }}>
                                        <RoundInvestors refetchRoundDetails={refetchRoundDetails} currency={isLoadingRoundDetails ? "" : roundDetails?.currency} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="round_investors" sx={{ paddingInline: 0, height: '100%', paddingTop: '0.5rem' }}>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: "calc(100vh - 180px)", paddingBottom: '0.5rem' }}>
                                        <RoundInvestorsNew refetchRoundDetails={refetchRoundDetails} currency={isLoadingRoundDetails ? "" : roundDetails?.currency} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                <TabPanel value="documents" sx={{ paddingInline: isSmallScreen ? "1rem" : "2rem", height: '100%' }}>
                                    <RoundDocuments refetchRoundDetails={refetchRoundDetails} data={roundDetails?.documents} />
                                </TabPanel>
                                {/* <TabPanel value="investors_documents" sx={{ paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                    <PerfectScrollbar style={{ height: "calc(100vh - 250px)" }}>
                                        <RoundDocuments data={roundDetails?.documents} />
                                    </PerfectScrollbar>
                                </TabPanel> */}
                            </TabContext>
                        </Box>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>
    )
}

export default ViewRound