import { useTheme } from '@emotion/react'
import { Box, Grid } from '@mui/material'
import { FetchAllStartupProfileViews } from 'api/index'
import React from 'react'
import { useSelector } from 'react-redux'
import AsyncTableListing from 'ui-components/AsyncTableListing'

const ViewProfile = () => {
  const theme = useTheme()
  const details = useSelector((state) => state?.startupDetails?.data);
  const column = [
    { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start', fixed: true },
    { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
    { Header: 'Date', accessor: 'formatted_date', minWidth: 150, align: 'center' },

  ];

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [sort, setSort] = React.useState(-1);
  const [search, setSearch] = React.useState(-1);

  // const data = [
  //     { 
  //     //   name: 'John Doe', 
  //       location: 'New York, USA', 
  //       date: '01-JAN-2024', 
  //       email: 'john.doe@example.com' 
  //     },
  //     { 
  //       name: 'Jane Smith', 
  //       location: 'Los Angeles, USA', 
  //       date: '15-FEB-2024', 
  //     //   email: 'jane.smith@example.com' 
  //     },
  //     { 
  //     //   name: 'Michael Johnson', 
  //       location: 'Chicago, USA', 
  //       date: '22-MAR-2024', 
  //       email: 'michael.johnson@example.com' 
  //     },
  //     { 
  //       name: 'Emily Davis', 
  //       location: 'Houston, USA', 
  //       date: '10-APR-2024', 
  //     //   email: 'emily.davis@example.com' 
  //     },
  //     { 
  //       // No name here
  //       location: 'Phoenix, USA', 
  //       date: '05-MAY-2024', 
  //       email: 'william.brown@example.com' 
  //     },
  //     { 
  //       name: 'Sophia Wilson', 
  //       location: 'Jaipur, INDIA', 
  //       date: '18-JUN-2024', 
  //     //   email: 'sophia.wilson@example.com' 
  //     },
  //     { 
  //       name: 'James Martinez', 
  //       location: 'Delhi, INDIA', 
  //       date: '08-JUL-2024', 
  //     //   email: 'james.martinez@example.com' 
  //     },
  //     { 
  //       // No name here
  //       location: 'Mumbai, INDIA', 
  //       date: '30-AUG-2024', 
  //       email: 'olivia.garcia@example.com' 
  //     },
  //     { 
  //       name: 'Benjamin Rodriguez', 
  //       location: 'Bangalore, INDIA', 
  //       date: '12-SEP-2024', 
  //     //   email: 'benjamin.rodriguez@example.com' 
  //     },
  //     { 
  //       // No name here
  //       location: 'Kolkata, INDIA', 
  //       date: '01-OCT-2024', 
  //       email: 'emma.martinez@example.com' 
  //     }
  //   ];


  // const { data: profileViewsData, isLoading: isLoadingProfileViews, mutateAsync: mutateAsyncProfileViews } = FetchAllStartupProfileViews()
  const { data: profileViewsData, isLoading: isLoadingProfileViews , isRefetching} = FetchAllStartupProfileViews({ id: details ? details?._id : '', page, limit, sort })
  console.log(profileViewsData, "profileViewsData")
  // React.useEffect(() => {
  //   details && mutateAsyncProfileViews({ startup_id: details ? details?._id : '', page, limit, sort })
  // }, [details, page, limit, sort])

  const formattedProfileViewsData = profileViewsData?.data && profileViewsData?.data?.length ? profileViewsData?.data?.map(item => ({
    ...item,
    name: item?.name ? item?.name != "" ? item?.name : item?.email : item?.email,
    location: `${item?.city ? item?.city + ', ' : ''}${item?.state ? item?.state + ', ' : ''}${item?.country ? item?.country : ''}`,
  })) : []


  return (
    <>
      <Grid container xs={12} pt={2} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
        {isLoadingProfileViews && isRefetching == false ?
          <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
            <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

            </Box>
          </Grid>
          :
          <Box width={"100%"} paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
            <AsyncTableListing
              // isLoading={isLoadingProfileViews}
              columns={column}
              data={formattedProfileViewsData}
              page={page}
              setPage={setPage}
              limit={profileViewsData?.limit}
              setLimit={setLimit}
              count={profileViewsData?.count}
              // tabValue={value}
              // data={[]}
              // title={"Investors"}
              // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
              // actions={Actions}
              // rowActions={rowActions}
              sx={{ borderRadius: '8px', border: `1px solid ${theme.palette.customColors.b50}` }}
            // refetchListing={refetchListing}
            />
          </Box>
        }

        {/* </Grid>} */}
        {/* <Grid item xs={2} lg={2}> */}

      </Grid>
    </>
  )
}

export default ViewProfile
