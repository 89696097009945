// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY || "AIzaSyDv7z77dUnLmyUz8sQpoWR_HeG_rqMbPqY",
    authDomain: process.env.FIREBASE_AUTH_DOMAIN || "backrr-saas.firebaseapp.com",
    // authDomain: process.env.FIREBASE_AUTH_DOMAIN || "startup.backrr.in",
    projectId: process.env.FIREBASE_PROJECT_ID || "backrr-saas",
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET || "backrr-saas.appspot.com",
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "323381609013",
    appId: process.env.FIREBASE_APP_ID || "1:323381609013:web:1b0fb91ecb9451d99d677c",
    measurementId: process.env.FIREBASE_MEASUREMENT_ID || "G-P13RP5DW0S"
};

export { firebaseConfig }
