import React from 'react'
import Cookies from "js-cookie"
import { useDispatch } from 'react-redux';
import { SET_MENU, SET_STARTUP_DETAILS } from 'store/actions';
import { useNavigate } from 'react-router-dom';

const LogoutScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    React.useEffect(() => {
        const handleLogOut = () => {
            dispatch({ type: SET_MENU, opened: false });
            Cookies.remove('jwt', { path: '/' });
            Cookies.remove('is_profile_complete', { path: '/' });
            Cookies.remove('email', { path: '/' });
            Cookies.remove('userDetails', { path: '/' });
            const cookies = document.cookie.split(";");
            dispatch({ type: SET_STARTUP_DETAILS, data: null });
            const providerCookies = Cookies.get("provider")
            if (providerCookies == "linkedin") {
                const url = 'https://www.linkedin.com/m/logout';
                const win = window.open(url
                    , '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=1, height=1, visible=none'
                );
                setTimeout(() => {
                    win.close();
                }, 3000);
            }

            cookies.forEach(cookie => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            });
            navigate('/login');
        };
        handleLogOut()
    },[])
    return (
        <div>Logging Out...</div>
    )
}

export default LogoutScreen